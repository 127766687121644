import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [currency, setCurrency] = useState('INR');
  const [profilePic,setProfilePic] = useState();
  const [username,setUserName] = useState();
  const [userEmail,setUserEmail] = useState();
 const [sugessiondata,setSugessionData] = useState()

  const handleLogout = () => {
    setToken(null); // Setting token to null
  };

  return (
    <AuthContext.Provider value={{ token,currency,profilePic,username,userEmail,sugessiondata, setToken,handleLogout,setCurrency,setSugessionData,setProfilePic,setUserName,setUserEmail }}>
      {children}
    </AuthContext.Provider>
  );
};


