import React from 'react';
import mobb from '../assets/mobb.jpg';
import web from '../assets/web.jpg'
import { Link } from 'react-router-dom';
const Banner = () => {
  return (
    <div className="relative h-screen/2 lg:-mt-28 -mt-40">
      {/* Web Image */}
      <img
        src={web}
        alt="Banner"
        className="hidden md:block w-full h-full object-cover rounded-b-[35px]"
      />
      {/* Mobile Image */}
      <img
        src={mobb}
        alt="Banner"
        className="block md:hidden w-full h-full object-cover rounded-b-[25px]"
      />
      <div className='absolute inset-0 bg-black bg-opacity-30 rounded-lg rounded-b-[35px]'></div>
      {/* Black Overlay */}
      {/* <div className="absolute inset-0 bg-black opacity-30"></div> */}
      {/* Centered Text */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white space-y-4 px-2">
        <h1 className="text-4xl md:text-7xl font-extrabold font-poppins tracking-tighter">Let's go Places</h1>
        <span className="font-poppins text-lg md:text-3xl tracking-widest text-[#87FFF7] text-center">Explore places with Touris mooze.</span>
        {/* spacer div */}
        <div className='h-5 lg:h-12'></div>
        {/* end spacer div */}
        <div className='flex gap-5 lg:gap-8'>
          <Link to={'/packages'}>
          <button className='bg-[#11a59b] px-4 lg:px-6 py-2 rounded-2xl font-poppins text-[12px] lg:text-md'>PICK A PACKAGE</button>
          </Link>
          <Link to={'/plan-your-trip'}>
            <button className='bg-[#1e75bb] px-4 lg:px-6 py-2 rounded-2xl font-poppins text-[12px] lg:text-md'>PLAN YOUR TRIP</button> 
            </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
