// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Popup from './components/Popup';
import Packages from './components/Packages';
import { AuthProvider } from './components/AuthContext';
import PackageDetails from './components/PackageDetails';
import Destinations from './components/Destinations';
import DestinationDetail from './components/DestinationDetail';
import ProfileDrawer from './components/ProfileDrawer';
import PlanYourTrip from './components/PlanYourTrip';
import Header from './components/Header';
import HomeHeader from './components/HomeHeader';
import TrendingDestinatios from './components/TrendingDestinatios';
import PopularPackages from './components/PopularPackages';
import { Provider } from 'react-redux';
import store from './components/store';
import Payment from './components/Payment';








const App = () => {

  const [popup, setPopup] = useState(true);

  const handleLoginClick = () => {
    setPopup(true);
  };

  const handleLogOutClick = () => {
   
    setPopup(false);
  };

  const handleCloseClick = () => {
    setPopup(false);
  };


  
  return (
    <AuthProvider>
      <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <HomeHeader onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} />
              <Home />
            </>
          } />
          <Route path="/destinations" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><Destinations /></>} />
          <Route path="/destination-details" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><DestinationDetail /></>} />
          <Route path="/packages" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><Packages /></>} />
          <Route path="/popular-packages" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PopularPackages /></>} />
          <Route path="/package-details" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PackageDetails /></>} />
          <Route path="/profile-drawer" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><ProfileDrawer /></>} />
          <Route path="/plan-your-trip" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PlanYourTrip /></>} />
          <Route path="/trending-destinations" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><TrendingDestinatios /></>} />
          <Route path="payment-page" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><Payment /></>} />
          <Route path="*" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><NotFound /></>} />
        </Routes>
        {popup && <Popup onCloseClick={handleCloseClick} />}
          
      </Router>
      </Provider>
     
    </AuthProvider>
  );
};

export default App;
