// src/components/Popup.js
import React, { useEffect, useState } from 'react';
import MobileInput from './MobileInput';
import { FaTimes } from 'react-icons/fa';
import Destinations from './Destinations';

const Popup = ({ onCloseClick }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    // Clean up the effect on component unmount
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isVisible]);

  const handleOtpSuccess = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed inset-0 flex items-center justify-center z-50 px-[2px]">
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
        <div className="bg-white shadow-lg relative z-10 w-full sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] rounded-2xl">
          <button
            onClick={() => {

              onCloseClick();
            }}
            style={{ border: 'none', margin: '2px', background: '#0FA499', position: 'absolute', zIndex: '2', cursor: 'pointer', padding: '3.5px', borderRadius: '30px', right: '0', display: '' }}
          >
            <FaTimes size={16} color="white" />
          </button>
          {/* content here */}
          <MobileInput onOtpSuccess={handleOtpSuccess} />
          
        </div>
      </div>
    )
  );
};

export default Popup;
